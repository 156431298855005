import { Box, Fade, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useTelegram } from "../hooks/useTelegram";
import { BackgroundGradient } from "../icons";
import { theme } from "../theme/theme";
import { getAccessToken } from "../services/login";

export function RootLayout() {
  const { tg } = useTelegram();
  const navigate = useNavigate();

  const [params, ,] = useSearchParams();

  const isErrorBackground = params.get("error-background") === "true";

  useEffect(() => {
    tg.ready();
    tg.expand();
    // tg.enableClosingConfirmation();

    tg.onEvent("backButtonClicked", () => {
      navigate("/");
      tg.BackButton.hide();
    });

    getAccessToken();
  }, [navigate, tg]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          background: isErrorBackground
            ? "radial-gradient(69.3% 31.97% at 50% 0%, #985757 0%, #221515 100%)"
            : "radial-gradient(79.3% 32.97% at 50% -5%, #2b473e 0%, #15221e 100%);",
        }}
      >
        {!isErrorBackground && (
          <Box
            sx={{
              position: "absolute",
              left: "-3%",
              right: "0",
              top: "0",
            }}
          >
            <BackgroundGradient />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            maxWidth: "375px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          {/* Background Infinite Video */}
          <Fade in timeout={2000}>
            <Box sx={{ opacity: ".05" }}>
              <Box
                component="video"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "fixed",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  opacity: ".05",
                  pointerEvents: "none",
                  zIndex: "1",
                }}
                playsInline
                muted
                autoPlay
                loop
                preload="auto"
                src={"/video.mp4"}
              />
            </Box>
          </Fade>

          <Box sx={{ position: "relative", zIndex: "99999" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
